import "./BottomSheet.scss";
import PropTypes, { InferProps } from "prop-types";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import theme from "../../theme";

const BottomSheetPropTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  sx: PropTypes.object
}

const BottomSheet = (props: InferProps<typeof BottomSheetPropTypes>) => {
  return (
    <Drawer
      PaperProps={{
        square: false,
        sx: {
          borderTopLeftRadius: theme.borderRadius.large,
          borderTopRightRadius: theme.borderRadius.large,
          borderBottomLeftRadius: "0 !important",
          borderBottomRightRadius: "0 !important",
          background: "transparent"
        }
      }}
      anchor="bottom"
      open={props.open}
      onClose={props.onClose}
    >
      <Box
        sx={{
          width: 'auto',
        }}
        role="presentation"
        onClick={props.onClose}
        onKeyDown={props.onClose}
      >
        {props.children}
      </Box>
    </Drawer >
  );
};

BottomSheet.propTypes = BottomSheetPropTypes;

BottomSheet.defaultProps = {

}

export default BottomSheet;
