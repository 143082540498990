import "./Chatbubble.scss";
import PropsTypes, { InferProps } from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ComponentPropsWithoutRef } from "react";

const ChatBubblePropTypes = {
  //TODO: Check if all of these are covered.
  variant: PropsTypes.oneOf(["user", "agent", "info", "welcome"]),
  content: PropsTypes.string,
  time: PropsTypes.string,
  sender: PropsTypes.string,
  info: PropsTypes.string,
  messageRef: PropsTypes.any,
  style: PropsTypes.object as any,
  type: PropsTypes.oneOf(["DOCUMENT", "TEXT"]),
}

const ChatBubble = (props: InferProps<typeof ChatBubblePropTypes & ComponentPropsWithoutRef<"div">>) => {
  const getBGColor = (variant: any) => {
    if (variant === "user") {
      return "text-link.main"
    } else if (variant === "agent" || variant === "welcome") {
      return "bg-tertiary.main"
    } else if (variant === "info") {
      return "bg-secondary.main"
    }
  }

  const getContentTypographyvariant = (variant: any) => {
    if (variant === "user") return "body-02"
    else if (variant === "agent" || variant === "welcome") return "body-02"
    else if (variant === "info") return "metadata-01"
  }

  const baseProperties = {
    borderRadius: 3,
    bgcolor: getBGColor(props.variant),
    width: "fit-content",
    maxWidth: "80%",
    padding: 5,
    pb: props.time ? 3 : 5,
    display: "flex",
    flexDirection: "column",
    overflowWrap: "anywhere",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word"
  } as const

  const customerBubble = {
    bgcolor: "text-link.main",
  } as const

  const agentBubble = {
    bgcolor: "bg-tertiary.main",
  } as const

  const infoBubble = {
    bgcolor: "bg-secondary.main",
    maxWidth: "100%",
    textAlign: "center",
    py: 3,
    px: 3
  } as const
  const getCurrentvariant = (variant: string) => {
    if (variant === "user") return customerBubble
    else if (variant === "agent" || variant === "welcome") return agentBubble
    else if (variant === "info") return infoBubble
    return infoBubble
  }
  return (
    <Box
      sx={{ ...baseProperties, ...(getCurrentvariant(props.variant || "")) }}
      mt={2}
      mb={5}
      ref={props.messageRef}
      id={props.id}
      style={props.style}
      onClick={props.onClick}
    >
      {
        props.sender &&
        <Typography variant="metadata-01-semibold"
          sx={{
            alignSelf: "flex-start",
            mb: 3
          }}>
          {props.sender}
        </Typography>
      }
      {
        <Typography
          variant={getContentTypographyvariant(props.variant)}
          sx={{
            textDecoration: props.type === "DOCUMENT" ? "underline" : ""
          }}
        >
          {props.content}
        </Typography>
      }

      <Typography
        variant={getContentTypographyvariant(props.variant)}>
        {props.info}
      </Typography>

      <Typography variant="metadata-01"
        sx={{
          alignSelf: "flex-end"
        }}>
        {props.time}
      </Typography>
    </Box>
  );
};

ChatBubble.propTypes = ChatBubblePropTypes;

export default ChatBubble;
