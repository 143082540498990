import "./GetHelp.scss";
import ChevronRight from "../../assets/chevron-right.svg";
import support from "../../assets/support.svg";
import send from "../../assets/send.svg";
import Chat from "../../assets/chat.svg";
import ListItem from "../../components/ListItem/ListItem";
import Typography from "@mui/material/Typography";
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import CurvedListItemContainer from '../../components/CurvedListItemContainer/CurvedListItemContainer';
import { Link } from "react-router-dom";
import routes from "../../utils/routes";
import { sendScribeEvent } from "../../utils/scribeHelper";
import i18n from '../../i18n/config';
import "./GetHelp.scss"
import { Box } from "@mui/system";
import React, { useEffect, useMemo, useState } from "react";
import theme from "../../theme";
import { openLink } from "../../utils/mobileComms";
import DOMPurify from "dompurify";
import ActionSheet from "../../components/ActionSheet/ActionSheet";
import { Button } from "@mui/material";
import Hazard from '../../assets/hazard.svg'
import { isClicked } from "../../utils/contactForm";
import useSnackbars from "../../context/Snackbar/Consumer";
import { getSnackbarArgs } from "../../utils/utils";
import { getUserStatus, UserStatus } from "../../utils/getHelp";
import DottedLoaderBox from "../../components/DottedLoaderBox/DottedLoaderBox";

const PHONE_NUMBER = process.env["REACT_APP_PHONE_NUMBER"] as string
const emailVerificationBottomSheet = 'email-verification-bottom-sheet'

const GetHelp = () => {
    const transactionIDStateData = window.history.state?.usr?.transactionID
    const emailVerificationLink = process.env["REACT_APP_EMAIL_VERIFICATION_LINK"] ?? ""
    const [userStatus, setUserStatus] = useState<UserStatus | undefined>();
    const [loading, setLoading] = useState(false);
    const { openSnackbar, closeSnackbar } = useSnackbars()
    const [showEmailVerificationBottomSheet, setShowEmailVerificationBottomSheet] = useState(false)

    useEffect(() => {//mount
        (async () => {
          setLoading(true)
          const getUserstatusResponse = await getUserStatus()
          if (getUserstatusResponse.error)
            openSnackbar(getSnackbarArgs({ onClose: closeSnackbar, uniqueKey: "get-user-status-error" }))
          else
            setUserStatus(getUserstatusResponse.res)
         setLoading(false)
        })()
      }, [])

    const findIfLinkIsParent = (event: React.MouseEvent<HTMLElement>) => {
        let node = event.target as HTMLElement | null
        const closestLink = node?.closest('A')

        if (closestLink) {
            event.preventDefault()
            if (closestLink.getAttribute('href')) {
                openLink(closestLink.getAttribute('href') as string)
            }
        }

        return null
    }

    const createMarkup = (content: string) => {
        return { __html: DOMPurify.sanitize(content) }
    }

    const onContactUsClick = () => {
        sendScribeEvent("help_getmorehelp_email_tap")
        if (!userStatus?.isEmailVerified) {
            setShowEmailVerificationBottomSheet(true)
        }
    }

    const verifyEmailPrimaryButton = useMemo(() => {
        return <Button
            variant="contained"
            onClick={() => {
                if (emailVerificationLink)
                    window.location.href = emailVerificationLink 
                else
                    openSnackbar(getSnackbarArgs({ onClose: closeSnackbar, uniqueKey: "verification-link-empty-error" }))
            }}
            sx={{
                backgroundColor: "brand-primary.main",
                '&:hover': {
                    backgroundColor: 'brand-primary.main'
                },
                flex: 1
            }}
        >
            <Typography variant="callout-02" p={5} sx={{ color: 'text-primary.main' }}>
                Verify now
            </Typography>
        </Button>
    }, [emailVerificationLink, openSnackbar, closeSnackbar])

    const verifyEmailSecondaryButton = useMemo(() => {
        return <Button
            variant="outlined"
            onClick={() => {
                setShowEmailVerificationBottomSheet(false)
            }}
            sx={{
                borderColor: "#FAFAFA",
                backgroundColor: "bg-secondary.main",
                '&:hover': {
                    borderColor: "#FAFAFA",
                    backgroundColor: "bg-secondary.main"
                },
                flex: 1
            }}
        >
            <Typography variant="callout-02" p={5} sx={{ color: 'text-primary.main' }}>
                Not now
            </Typography>
        </Button>
    }, [])

    return (
        <div id="report-fraud">
            <Typography mb={5} display="block" variant="callout-01" color="text-primary.main">{i18n.t("helpcentre_get_help_page_title")}</Typography>
            <CurvedListItemContainer>
                <Link
                    to={routes.chat.path}
                    state={{ transactionIDStateData }}
                    className="no-text-decoration"
                    onClick={() => { sendScribeEvent("help_getmorehelp_chat_tap") }}
                >
                    <ListItem
                        primaryText={
                            <Typography variant="body-02" sx={{ color: "text-primary.main" }}>{i18n.t("helpcentre_get_help_page_primary_help_text_1")}</Typography>
                        }
                        secondaryText={
                            <Typography variant="body-03" sx={{ color: "text-tertiary.main" }}>
                                {i18n.t("helpcentre_get_help_page_primary_help_text_2")}
                            </Typography>
                        }
                        tertiaryText={
                            <Typography mt={3} variant="metadata-01-semibold" sx={{ color: "text-primary.main" }}>
                                <Chip label={i18n.t("helpcentre_get_help_page_primary_help_text_3")} color="brand-tertiary" size="small" sx={{ borderRadius: 1, p: 2, '& .MuiChip-label': { px: 0 } }} />
                            </Typography>
                        }
                        primaryIcon={
                            <IconButton><img src={Chat} alt="" /></IconButton>
                        }
                        secondaryIcon={
                            <IconButton><img src={ChevronRight} alt="" /></IconButton>
                        }
                        isCurved={false}
                    />
                </Link>
                <a href={`tel:+60${PHONE_NUMBER}`} className="no-text-decoration" onClick={() => { sendScribeEvent("help_getmorehelp_call_tap") }}>
                    <div className="no-select">
                        <ListItem
                            primaryText={
                                <Typography variant="body-02" sx={{ color: "text-primary.main" }}>{i18n.t("helpcentre_get_help_page_secondary_help_text_1")}</Typography>
                            }
                            secondaryText={
                                <Typography variant="body-03" sx={{ color: "text-tertiary.main" }}>
                                    {i18n.t("helpcentre_get_help_page_secondary_help_text_2")}
                                </Typography>
                            }
                            primaryIcon={
                                <IconButton><img src={support} alt="" /></IconButton>
                            }
                            secondaryIcon={
                                <IconButton><img src={ChevronRight} alt="" /></IconButton>
                            }
                            isCurved={false}
                        />
                    </div>
                </a>
                { userStatus?.isOnboarded && userStatus?.isFirstFundDone &&
                    <Link
                        to={userStatus.isEmailVerified ? routes.contactUs.path : ""}
                        state={{ transactionIDStateData }}
                        className="no-text-decoration"
                        onClick={onContactUsClick}
                    >
                        <ListItem
                            primaryText={
                                <Typography variant="body-02" sx={{ color: "text-primary.main" }}>{i18n.t("helpcentre_get_help_page_tertiary_help_text_1")}</Typography>
                            }
                            secondaryText={
                                <Typography variant="body-03" sx={{ color: "text-tertiary.main" }}>
                                    {i18n.t("helpcentre_get_help_page_tertiary_help_text_2")}
                                </Typography>
                            }
                            primaryIcon={
                                <IconButton><img src={send} alt="" /></IconButton>
                            }
                            secondaryIcon={
                                <IconButton><img src={ChevronRight} alt="" /></IconButton>
                            }
                            isCurved={false}
                        />
                    </Link>
                }
            </CurvedListItemContainer>
            <div className="fixed-bottom">
                <Box
                    onClick={(event: React.MouseEvent<HTMLElement>) => { findIfLinkIsParent(event) }} id='dataPrivacyPolicy'
                    component={"section"} sx={{
                    color: 'text-primary.main',
                    fontFamily: theme.typography.fontFamily,
                    typography: 'body-03',
                    "a": {
                        color: 'text-link.main',
                    },
                    'h2': {
                        typography: 'callout-01',
                    },
                    'h3': {
                        typography: 'heading-05',
                    },
                    'h4': {
                        typography: 'callout-02',
                        color: 'text-tertiary.main',
                    },
                    'h5': {
                        typography: 'callout-03',
                        color: 'text-tertiary.main',
                    },
                }}
                    dangerouslySetInnerHTML={createMarkup(`\n\n<div>${i18n.t("helpcentre_data_privacy_policy_body_1")} <a href="https://gxbank.my/data-privacy-policy" target="_self" rel="external nofollow noopener noreferrer">${i18n.t("generic_data_privacy_policy")}</a>${i18n.t("helpcentre_data_privacy_policy_body_2")}</div>\n` || '')}>
                </Box>
            </div>
            <ActionSheet
                id={emailVerificationBottomSheet}
                icon={<img src={Hazard} alt="" />}
                open={showEmailVerificationBottomSheet}
                onClose={(e) => {
                    if (!isClicked(emailVerificationBottomSheet, e.target)) {
                        setShowEmailVerificationBottomSheet(false)
                    }
                }}
                title="Looks like your email is unverified"
                description="Please verify your email now before returning to submit your query."
                primaryButton={verifyEmailPrimaryButton}
                secondaryButton={verifyEmailSecondaryButton}
            />
            {
            loading ?
              <Box
                sx={{
                  zIndex: "3000",
                }}
              >
                <Box
                  sx={{
                    height: "100vh",
                    width: "100%",
                    position: "fixed",
                    top: "0",
                    left: "0",
                    background: "black"
                  }}>
                </Box>
                <DottedLoaderBox
                  sx={{
                    position: "fixed",
                    m: 'auto',
                    top: "50%",
                    left: '50%',
                    transform: "translate(-50%, -50%)"
                  }}
                />
              </Box> :
              <></>
          }
        </div>
    )
}

export default GetHelp