import httpClient from './base'; //change this if your axios instance is elsewhere

const getMessages = async (data: object, options: object = {}) => {
    const res = await httpClient.post("/v2/get-messages", data, options)
    return res.data
}

const sendMessage = async (data: object) => {
    const res = await httpClient.post("/v1/send-message", data)
    return res.data
}

const getOngoingChat = async () => {
    const res = await httpClient.post("/v1/get-ongoing-chat")
    return res.data
}

const getChatIssueTypes = async () => {
    const res = await httpClient.get("/v1/get-issue-types/chat")
    return res.data
}

export { getMessages, sendMessage, getOngoingChat, getChatIssueTypes }
