import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import PropTypes, { InferProps } from "prop-types";
import { ComponentPropsWithoutRef } from 'react';

const ListItemPropTypes = {
  primaryText: PropTypes.element,
  secondaryText: PropTypes.element,
  tertiaryText: PropTypes.element,
  primaryIcon: PropTypes.element,
  secondaryIcon: PropTypes.element,
  isCurved: PropTypes.bool,
  sx: PropTypes.object,
}

const ListItem = (props: InferProps<typeof ListItemPropTypes & ComponentPropsWithoutRef<"div">>) => {
  function classes() {
    return `${props.isCurved ? 'curve-endings' : ''}`;
  }

  return (
    <Stack onClick={props.onClick} direction="row" className={classes()} sx={{ p: 5, mt: '1px', backgroundColor: "bg-secondary.main", ...props.sx }}>
      <Stack direction="row" width="100%" spacing={3}>
        {
          props.primaryIcon &&
          <Box sx={{ mr: 3, alignSelf: 'center' }}>
            {props.primaryIcon}
          </Box>
        }
        <Box width="100%">
          <Stack>
            {props.primaryText}
            {props.secondaryText}
            {props.tertiaryText}
          </Stack>
        </Box>
        {props.secondaryIcon}
      </Stack>
    </Stack>
  );
}

ListItem.propTypes = ListItemPropTypes;

ListItem.defaultProps = {
  isCurved: true,
  sx: {},
}

export default ListItem;