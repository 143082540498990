import "./PdfViewer.scss";
import PropTypes, { InferProps } from "prop-types";
import { useMemo, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import theme from "../../theme";
import NextPageButton from '../../assets/NextPageButton.svg';
import PrevPageButton from '../../assets/PrevPageButton.svg';
import DottedLoaderBox from "../DottedLoaderBox/DottedLoaderBox";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
// import 'react-pdf/dist/esm/Page/TextLayer.css';
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack5";
// pdfjs.GlobalWorkerOptions.workerSrc = './pdf.worker.min@2.12.313.js'
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const PdfViewerPropTypes = {
  file: PropTypes.string.isRequired
}

const PdfViewer = (props: InferProps<typeof PdfViewerPropTypes>) => {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset: number) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const dottedLoader = useMemo(() => {
    return (
      <DottedLoaderBox
        sx={{
          position: "fixed",
          m: 'auto',
          top: "50%",
          left: '50%',
          transform: "translate(-50%, -50%)"
        }}
      />
    )
  }, [])

  const grid = document.getElementById('parent-grid')
  const pageWidth = useMemo((): number => {
    let padding = grid ? parseFloat(window.getComputedStyle(grid).getPropertyValue('padding')) * 2 : parseFloat(theme.spacing(6)) * 2
    return window.innerWidth - padding
  }, [grid])

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Document
        file={props.file}
        loading={dottedLoader}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page pageNumber={pageNumber} width={pageWidth} />
      </Document>
      {
        numPages &&
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 3 }}>
          {
            <Button onClick={previousPage} sx={{ visibility: pageNumber > 1 ? 'visible' : 'hidden' }}>
              <img src={PrevPageButton} alt="" />
            </Button>
          }
          <Typography sx={{ justifySelf: 'center' }}>
            {pageNumber}/{numPages}
          </Typography>
          {
            <Button onClick={nextPage} sx={{ visibility: pageNumber < numPages ? 'visible' : 'hidden' }}>
              <img src={NextPageButton} alt="" />
            </Button>
          }
        </Box>
      }
    </Box>
  );
};

PdfViewer.propTypes = PdfViewerPropTypes;

export default PdfViewer;
