import "./BottomSheetSelection.scss";
import { Box, IconButton, TextField, Typography } from "@mui/material";
import BottomSheet from "../BottomSheet/BottomSheet";
import ListItem from "../ListItem/ListItem";
import { ComponentPropsWithoutRef, useState } from "react";
import ChevronDown from '../../assets/chevron-down-small.svg';
import Cross from '../../assets/cross.svg';
import RadioActive from '../../assets/radio-active.svg'
import RadioInactive from '../../assets/radio-inactive.svg'
import theme from "../../theme";
import i18n from '../../i18n/config';

type BottomSheetSelectionProps = {
  label: string,
  listTitle: string,
  options: string[],
  value: string
  onChange?: (value: string) => void
}

const BottomSheetSelection = (props: BottomSheetSelectionProps & Omit<ComponentPropsWithoutRef<"select">, "onChange">) => {
  const [open, setOpen] = useState(false)
  const [currentSelection, setCurrentSelection] = useState(props.value)

  const iconComponentClickHandler = (event: any) => {
    setOpen(true)
  }

  return (
    <>
    <TextField
      id={props.id}
      fullWidth
      value={i18n.t(currentSelection)}
      focused={false}
      variant="standard"
      label={props.label}
      color="bg-tertiary"
      InputProps={{
        endAdornment: (<IconButton
            onClick={iconComponentClickHandler}>
            <img src={ChevronDown} alt="" />
        </IconButton>),
        sx: {
            '&::before': { borderBottom: `2px solid ${theme.palette["bg-tertiary"].main}` },
            '&:hover:not(.Mui-disabled)::before': { borderBottom: `2px solid ${theme.palette["bg-tertiary"].main}` },
            whiteSpace: "pre-wrap"
        }
      }}
      onClick={() => setOpen(!open)}
      sx={{
        my: 5,
        '.MuiInput-input': { color: 'text-primary.main', ...theme.typography["body-02"] },
        label: { color: 'text-placeholder.main', ...theme.typography["body-02"] },
        'label.MuiInputLabel-shrink': { color: 'text-tertiary.main', ...theme.typography["body-02"] },
      }}
    />
            <BottomSheet open={open} onClose={() => setOpen(false)}>
                <Box sx={{ 
                        backgroundColor: "bg-secondary.main", 
                        px: 3, 
                        paddingTop: 6, 
                        paddingBottom: '36px',
                    }} 
                    id={"bottom-sheet-selection"}
                >
                    <ListItem
                        sx={{
                            borderBottomLeftRadius: "0 !important",
                            borderBottomRightRadius: "0 !important",
                            paddingBlock: 0,
                            marginBottom: '24px'
                        }}
                        primaryText={
                            <Typography variant="heading-04" sx={{ color: "text-primary.main" }}>{props.listTitle}</Typography>
                        }
                        secondaryIcon={
                            <img src={Cross} alt="" />
                        }
                    />
                <Box>
                    {
                        props.options?.map((option, index) => {
                        return (
                                <ListItem
                                    key={index}
                                    id={props.id}
                                    onClick={() => {
                                        setCurrentSelection(option)
                                        props.onChange?.(option)
                                    }}
                                    sx={{
                                        whiteSpace: "pre-wrap",
                                        color: 'text-primary.main',
                                        backgroundColor: 'bg-secondary.main',
                                        '&.Mui-selected:hover': { backgroundColor: 'bg-tertiary.main' },
                                        '&.Mui-selected:focus': { backgroundColor: 'bg-tertiary.main' },
                                        p: 5,
                                        '&:hover': { backgroundColor: 'bg-tertiary.main' },
                                        '&:focus': { backgroundColor: 'bg-tertiary.main' }
                                    }}
                                    primaryText={
                                        <Typography variant="body-02" sx={{ color: "text-primary.main", fontSize: 16 }}>{i18n.t(option)}</Typography>
                                    }
                                    secondaryIcon={option === currentSelection ? <img src={RadioActive} alt="" /> : <img src={RadioInactive} alt="" />}
                                >
                                    {option}
                                </ListItem>
                        )
                        })
                    }
                </Box>
            </Box>
        </BottomSheet>
        </>
  );
};

export default BottomSheetSelection;
