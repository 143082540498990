import "./ActionSheet.scss";
import { Box, Typography } from "@mui/material";
import BottomSheet from "../BottomSheet/BottomSheet";
import { ReactNode } from "react";

type ActionSheetProps = {
    open: boolean
    onClose: (...params: any) => void
    icon?: ReactNode
    id? : string
    title: string
    description: string
    primaryButton: ReactNode
    secondaryButton?: ReactNode
}

const ActionSheet = (props: ActionSheetProps) => {
  return (
    <BottomSheet open={props.open} onClose={props.onClose}>
        <Box sx={{ 
                backgroundColor: "bg-secondary.main", 
                px: 5, 
                paddingTop: 6, 
                paddingBottom: '36px',
                display: 'flex',
                flexDirection: 'column',
                gap: '24px'
            }} 
            id={props.id ?? "action-sheet"}
            alignItems='center'
        >
            {props.icon}
            <Box display='flex' flexDirection='column' alignItems='center' gap={3} paddingX={2}>
                <Typography variant="heading-04" textAlign='center' sx={{ color: "text-primary.main" }}>{props.title}</Typography>
                <Typography variant="body-03" textAlign='center' sx={{ color: "text-primary.main" }}>{props.description}</Typography>
            </Box>
            <Box display='flex' flexDirection='row' gap={4} alignItems='stretch' width='100%'>
                {props.secondaryButton}
                {props.primaryButton}
            </Box>
        </Box>
    </BottomSheet> 
  );
};

export default ActionSheet;
