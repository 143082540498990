import React, { useEffect, useRef } from "react"
import { useNavigate, useParams } from "react-router-dom"
import useSnackbars from "../context/Snackbar/Consumer"
import { closeWebView } from "./mobileComms"
import { DOMAIN_RETAIL } from "../constants/domain"

const TIME_ZONE = Intl.DateTimeFormat().resolvedOptions().timeZone

const debounce = (callback: Function, delay = 300) => {
  let timeout: NodeJS.Timeout
  return (...args: any[]) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      callback(...args)
    }, delay)
  }
}

const throttle = (callback: Function, delay = 1000) => {
  let shouldWait = false
  let waitingArgs: any[] | null
  const timeoutFunc = () => {
    if (waitingArgs == null) {
      shouldWait = false
    } else {
      callback(...waitingArgs)
      waitingArgs = null
      setTimeout(timeoutFunc, delay)
    }
  }

  return (...args: any[]) => {
    if (shouldWait) {
      waitingArgs = args
      return
    }

    callback(...args)
    shouldWait = true
    setTimeout(timeoutFunc, delay)
  }
}

const withRouter = (WrappedComponent: typeof React.Component) => {
  return (props: any) => {
    const params = useParams();
    const navigate = useNavigate();

    return <WrappedComponent {...props} params={params} navigate={navigate} />;
  }
}

/**
 * Hook for setInterval which takes care of updating callback and clearing interval
 * References:
 * 1. https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 * 2. https://usehooks-ts.com/react-hook/use-interval
 */
function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef<any>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const getSnackbarArgs = ({
  primaryMessage = "",
  secondaryMessage = "Oops, something went wrong! Please try again.",
  onCloseActionContent = "Dismiss",
  uniqueKey = "uniqueKey",
  variant = "error",
  onClose = () => { },
}) => {
  return {
    primaryMessage: primaryMessage,
    secondaryMessage: secondaryMessage,
    onCloseActionContent: onCloseActionContent,
    onClose: onClose,
    uniqueKey: uniqueKey,
    variant: variant,
  }
}

const withSnackBar = (WrappedComponent: any) => {
  return (props: any) => {
    const { openSnackbar, closeSnackbar } = useSnackbars()
    return <WrappedComponent {...props} openSnackbar={openSnackbar} closeSnackbar={closeSnackbar} />;
  }
}

const goBackHelper = (routeProps: any, navigator: Function) => {
  console.log(routeProps, "routeProps being recieved");
  if (routeProps?.handleNavigation)
    routeProps.handleNavigation()
  else if (routeProps?.path)
    navigator(routeProps.path)
  else if (window.history.state?.usr?.isFirstPage)
    closeWebView()
  else
    navigator(-1)
}

const bytesToMB = (bytes: number) => bytes / (1024 ** 2);

const setLocale = async (locale: string) => {
  sessionStorage.setItem("i18nextLng", locale)
}

const getLocale = (): string => {
  var locale = sessionStorage.getItem("i18nextLng")
  
  if (locale === null || locale === "") {
    return "en"
  } else {
    return JSON.stringify(locale).replaceAll(/"/g, "")
  }
}

const setDomain = async (activeProfileDomain: string) => {
  sessionStorage.setItem("activeProfileDomain", activeProfileDomain)
}

const getDomain = (): string => {
  var activeProfileDomain = sessionStorage.getItem("activeProfileDomain")
  
  if (activeProfileDomain === null || activeProfileDomain === "") {
    return DOMAIN_RETAIL
  } else {
    return JSON.stringify(activeProfileDomain).replaceAll(/"/g, "")
  }
}

export {
  TIME_ZONE,
  debounce,
  throttle,
  withRouter,
  useInterval,
  getSnackbarArgs,
  withSnackBar,
  bytesToMB,
  goBackHelper,
  setLocale,
  getLocale,
  setDomain,
  getDomain
}